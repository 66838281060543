import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiUserQuestionnaireService } from '@services/api-user-questionnaire.service';
import { QuestionnaireAnswers } from '@shared/models/proofreader/questionnaire-answers';
import { UserQuestionnaire } from '@shared/models/user/user-questionnaire';
import { ErrorService } from '@shared/utils/error/error.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, catchError, distinctUntilChanged, startWith, Subject, takeUntil } from 'rxjs';
import { Observable } from 'rxjs';

@Component({
  selector: 'red-adaptive-learning-survey-modal',
  templateUrl: './adaptive-learning-survey-modal.component.html',
  styleUrls: ['./adaptive-learning-survey-modal.component.scss']
})
export class AdaptiveLearningSurveyModalComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$: Subject<void>;

  private isLoadingSource$: BehaviorSubject<boolean>;

  public isLoading$: Observable<boolean>;

  public title:        string;
  public body:         string;
  public confirmBtn:   string;
  public cancelBtn:    string;
  public errorMessage: string;

  public questionnaire: UserQuestionnaire;
  public questions: FormGroup;
 
  constructor(
    private questionnaireService: ApiUserQuestionnaireService,
    private errorService:         ErrorService,
    private toastr:               ToastrService,
    private dialogRef: MatDialogRef<AdaptiveLearningSurveyModalComponent>,
  ) { 
    this.unsubscribe$ = new Subject<void>();
  }

  ngOnInit() {
    this.initIsLoading();
    this.getQuestionnaire(); 
  }

  private initIsLoading() {
    this.isLoadingSource$ = new BehaviorSubject<boolean>(false);
    this.isLoading$       = this.isLoadingSource$.asObservable().pipe(
      startWith(false as boolean),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    );
  }

  private updateIsLoading(status: boolean) {
    this.isLoadingSource$.next(status);
  }
 
  private getQuestionnaire(){
    const successCallback = (questionnaire: UserQuestionnaire) => {
      this.questionnaire = questionnaire;
    }

    const errorCallback = (error: HttpErrorResponse) =>{
      return this.errorService.handleFormError(error);
    }

    this.questionnaireService
    .getQuestionnaireFeedback()
    .pipe(catchError(errorCallback))
    .subscribe(successCallback)
  }
 

  public saveQuestionnaire(answers: QuestionnaireAnswers) {
    const callback = () => this.toastr.info(
      'Você pode voltar e terminar mais tarde',
      'Avaliação salva com sucesso'
    );
    this.submitQuestionnaire(answers, false, callback);
    this.closeModal();
  }

  public concludeQuestionnaire(answers: QuestionnaireAnswers) {
    const callback = () => this.toastr.success(
      'A avaliação foi concluída e não poderá mais ser alterada',
      'Avaliação enviada com sucesso'
    );

    this.submitQuestionnaire(answers, true, callback);
    this.closeModal();
  }
 

  private submitQuestionnaire(
    answers: QuestionnaireAnswers,
    shouldConclude: boolean,
    callback: VoidFunction
  ) {
    const handleSuccess = () => {
      this.updateIsLoading(false);
      if (typeof callback === 'function') callback();
      this.getQuestionnaire();
    };

    const handleError = () => this.updateIsLoading(false);

    this.updateIsLoading(true); 
    this.questionnaireService
        .submitQuestionnaire(this.questionnaire.provaId, answers, shouldConclude, handleError)
        .subscribe(handleSuccess);
 
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
 
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
