import { Component, Inject, OnDestroy, OnInit }                                from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { DOCUMENT }                                                            from '@angular/common';
import { MatDialog, MatDialogRef }                                             from '@angular/material/dialog';

import { Observable, Subject }                                        from 'rxjs';
import { distinctUntilKeyChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { AuthUser } from '@models/user/auth-user';
import { Script }   from '@models/utils/script';

import { gtmScript }       from '@utils/script/gtm-script';
import { freshdeskScript } from '@shared/utils/script/freshdesk-script';
import {
  AdaptiveLearningSurveyModalComponent
}                          from '@shared/modals/adaptive-learning-survey-modal/adaptive-learning-survey-modal.component';

import { LayoutService }    from '@layout/layout.service';
import { ScriptService }    from '@utils/script/script.service';
import { AnalyticsService } from '@services/analytics.service';
import { UserService }      from '@services/user.service';
import { UserType }         from '@shared/models/user/user-type';

declare const RybenaDOM: any;


@Component({
  selector:    'red-root',
  templateUrl: './app.component.html',
  styleUrls:   [ './app.component.scss' ]
})
export class AppComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$: Subject<void>;

  private routeData$: Observable<Data>;
  private activeUser$: Observable<AuthUser>;
  private uniqueNavigation$: Observable<NavigationEnd>;

  public document: Document;

  public AdaptiveLearningDialog: MatDialogRef<AdaptiveLearningSurveyModalComponent>;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private scriptService: ScriptService,
    private analyticsService: AnalyticsService,
    private layoutService: LayoutService,
    private userService: UserService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private _document: any
  ) {
    this.unsubscribe$ = new Subject<void>();
    this.activeUser$  = this.userService.getActiveUser();
    this.document     = this._document as Document;
  }

  private static mapChildRouteData(route: ActivatedRouteSnapshot): Data {
    while (route.firstChild) { route = route.firstChild; }
    return route.data;
  }

  public ngOnInit(): void {
    this.initRouterObservables();
    this.loadRybena();
    this.loadFreshdesk();
    this.loadScript({ name: 'gtm' }, gtmScript).subscribe();
    this.userService.initUser().subscribe();

    this.activeUser$.pipe(
      switchMap(() => this.activeUser$)
    ).subscribe((user: AuthUser) => {
      if (user?.indRespondePesquisa) this.adaptiveLearningSurveyDialogOpen(user.perfil);
    });

  }

  private initRouterObservables() {
    this.uniqueNavigation$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilKeyChanged<NavigationEnd>('urlAfterRedirects'),
      takeUntil(this.unsubscribe$)
    );

    this.routeData$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => AppComponent.mapChildRouteData(this.activatedRoute.snapshot)),
      takeUntil(this.unsubscribe$)
    );

    this.handleSiteSections();
    this.handlePageViews();
    this.handleLoginExceptions();
  }

  private handleSiteSections() {
    this.routeData$.subscribe(data => this.layoutService.setSiteSection(data['section']));
  }

  private handlePageViews() {
    this.uniqueNavigation$.pipe(
      switchMap(() => this.scriptService.scriptReady('gtm'))
    ).subscribe(() => this.analyticsService.pageView());
  }

  // verify if user needs to change passwords and/or accept contract
  private handleLoginExceptions() {
    const filterLoginExceptions = (user: AuthUser): boolean => {
      if (!user) return false;
      return user.alterarSenha || ( user.aceiteContrato && !this.userService.contractAccepted );
    };

    this.uniqueNavigation$.pipe(
      filter((event: NavigationEnd) => !event.urlAfterRedirects.includes('/home')),
      switchMap(() => this.activeUser$),
      filter(filterLoginExceptions)
    ).subscribe((user: AuthUser) => {

      if (user.aceiteContrato) {
        this.router.navigate(
          [ '/home' ],
          { queryParams: { contrato: true } }
        ).then();
      } else if (user.alterarSenha) {
        this.router.navigate(
          [ '/home' ],
          { queryParams: { trocarSenha: true } }
        ).then();
      }

    });
  }

  private loadRybena() {
    const scriptTag: Script = {
      name: 'rybena',
      src:  'https://cdn.rybena.com.br/dom/master/latest/rybena.js?positionBar=right&positionPlayer=right&backgroundColorBar=#1d2c3d'
    };

    const callback = (script: Script) => {
      if (script.loaded) RybenaDOM.getInstance();
      if (script.error) console.error(script.error);
    };

    this.loadScript(scriptTag)
        .pipe(filter((script: Script) => Boolean(script)))
        .subscribe(callback);
  }

  private loadFreshdesk() {
    this.activeUser$.pipe(
      filter((user: AuthUser) => user?.parceiroId === 27)
    ).subscribe(() => {
      const callback = (script: Script) => {
        if (script.error) console.error(script.error);
      };

      const scriptTag: Script = {
        name: 'freshdesk-ext',
        src:  'https://widget.freshworks.com/widgets/9000000957.js'
      };

      this.loadScript({ name: 'freshdesk-local' }, freshdeskScript)
          .pipe(filter((script: Script) => Boolean(script)))
          .subscribe(callback);

      this.loadScript(scriptTag)
          .pipe(filter((script: Script) => Boolean(script)))
          .subscribe(callback);
    });
  }

  private loadScript(
    config: Script,
    content?: string
  ): Observable<Script> {
    const scriptDefaults = { defer: true, async: true };

    if (content) this.scriptService.registerScriptContent(config.name, content);

    return this.scriptService.load({ ...scriptDefaults, ...config });
  }


  public adaptiveLearningSurveyDialogOpen(typeUser: UserType) {
    this.AdaptiveLearningDialog = this.dialog.open(AdaptiveLearningSurveyModalComponent, {
      disableClose: false,
      maxWidth:     800
    });

    const ending: string = typeUser === 'COORDENADOR_ESCOLA' || typeUser === 'COORDENADOR_TURMA'
                           ? 'para seus alunos desenvolverem habilidades especificas'
                           : 'para você desenvolver habilidades específicas de redação';

    this.AdaptiveLearningDialog.componentInstance.title = 'Pesquisa sobre ensino adaptativo';

    this.AdaptiveLearningDialog.componentInstance.body = `Olá! Recentemente, sua escola teve acesso à nossa nova tecnologia de ensino,
      que disponibilizou conteúdos personalizados ${ ending }. `
      + '</br></br>Agora queremos ouvir a sua opinião. Para aprimorarmos ainda mais essa nova ferramenta, compartilhe a sua experiência'
      + 'conosco respondendo a este rápido formulário. '
      + '</br></br>Suas respostas nos ajudarão a melhorar ainda mais essa funcionalidade!';

    this.AdaptiveLearningDialog.componentInstance.cancelBtn = 'Fechar';

    this.AdaptiveLearningDialog.afterClosed().subscribe(result => {
      if (!result) return;

      this.AdaptiveLearningDialog = null;
    });
  }


  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.layoutService.completeObservables();
  }
}
