// noinspection JSUnusedGlobalSymbols
// noinspection SpellCheckingInspection
export const environment = {
  production: true,
  siteUrl: 'https://portal.redacaonota1000.com.br',
  legacyUrl: 'https://www.redacaonota1000.com.br',
  cmUrl: 'http://cm.redacaonota1000.com.br',
  cmApiUrl:'https://cm-services.redacaonota1000.com.br',
  apiUrl: 'https://services-portal.redacaonota1000.com.br/api/v2',
  healthUrl: 'https://services.redacaonota1000.com.br/actuator/health',
  fbLocalAppId: '1947548505342128',
  fbAppId: '970783532984902',
  gtmId: 'GTM-KSFX65KS',
  rdApiKey: '5e213249c3bc7186493bfbc69ef7cf0c',
};
